import React from "react";

// import fullIcon from "../assets/hb-icon.svg";
import fullIcon from "../assets/HB-Logo-Dark-Colored.svg";
// import rocketIcon from "../assets/hb-rocket-icon.svg";
import rocketIcon from "../assets/HB-Rocket-Only.svg";

interface IProps {
  rocketOnly: boolean;
  color: boolean;
}

export const HyperBrewLogo = ({ rocketOnly, color }: IProps) =>
  (!rocketOnly && (
    <img
      className="hb-logo hb-logo-full"
      alt="Hyper Brew Logo"
      src={fullIcon}
    />
  )) || (
    <img
      src={rocketIcon}
      alt="Hyper Brew Logo"
      className="hb-logo hb-logo-rocketonly"
    />
  );
