import React, { useState } from "react";
import { PageContextProvider } from "./usePageContext";
import type { PageContext } from "./types";
import { HyperBrewLogo } from "../pages/components/hyper-brew-logo";

import { Link } from "./Link";
import cn from "classnames";

import { BarsIcon } from "../pages/components/bars-icon";

import "../pages/app.scss";
import "../pages/variables.scss";

export const PageWrapper = ({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const urlPath = pageContext.urlPathname;
  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <div>
          <div className="app">
            <header className="app-header">
              <a className="header--logo" href="/" title="Hyper Brew">
                <HyperBrewLogo rocketOnly={false} color={false} />
              </a>

              <div className={cn("navbar", { "navbar-hidden": !menuOpened })}>
                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected":
                      urlPath.indexOf("/landings/boost-video") === 0,
                  })}
                  href="/landings/boost-video"
                >
                  How We Help
                </Link>
                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected": urlPath.indexOf("/about") === 0,
                  })}
                  href="/about/"
                >
                  About
                </Link>
                {/* <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected":
                      urlPath.indexOf("/services") === 0,
                  })}
                  href="/services/"
                >
                  Services
                </Link> */}
                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected":
                      urlPath.indexOf("/projects") === 0,
                  })}
                  href="/projects/"
                >
                  Projects
                </Link>
                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected": urlPath.indexOf("/blog") === 0,
                  })}
                  href="/blog/"
                >
                  Blog
                </Link>

                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected": urlPath.indexOf("/tools") === 0,
                  })}
                  href="/tools/"
                >
                  Tools
                </Link>
                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected":
                      urlPath.indexOf("/resources") === 0,
                  })}
                  href="/resources/"
                >
                  Resources
                </Link>
                <Link
                  className={cn("navbar--button", {
                    "navbar--button--selected":
                      urlPath.indexOf("/contact") === 0,
                  })}
                  href="/contact/"
                >
                  Contact
                </Link>
              </div>
              <div
                className="mobile-menu-toggle"
                onClick={() => {
                  setMenuOpened(!menuOpened);
                }}
              >
                <BarsIcon />
              </div>
            </header>
            <div className="app-content-wrapper">
              <div className="app-content">{children}</div>
            </div>
          </div>
        </div>
      </PageContextProvider>
    </React.StrictMode>
  );
};
